<template>
  <v-card :loading="false" color="cardBackground" class="flex d-flex flex-column " max-width="374" :to="docName ? { name: 'app.viewDoc', params: { docName: docName } } : ''">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
    </template>

    <v-img contain height="250" v-if="fileName" :src="require(`../../../assets/doc/${fileName}`)"></v-img>

    <v-card-title>{{ title }}</v-card-title>

    <v-card-text>
      <div>{{ description }}</div>
    </v-card-text>

    <!-- <v-card-actions>
        <v-btn color="success" text :to="{ name: 'app.viewDoc', params: { docName: 'motor-vehicle-purchase-agreement' } }">
          View Larger
        </v-btn>
      </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  name: 'DocumentCard',
  props: {
    fileName: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    docName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    footerText: '© ' + new Date().getFullYear() + ' - The Title Girl',
  }),
};
</script>

<style scoped></style>
